import React from 'react'
import './search.scss'
import { FiSearch } from "react-icons/fi";

const Index = ({ searchCallBack, placeholder }: any) => {
    let debounce: any;
    const handleSearch = (e: any) => {
        clearTimeout(debounce)
        debounce = setTimeout(() => {
            searchCallBack(e)
        }, 1000);
    }
    return (
        <>
            <div className="col-md-2 position-relative mb-4">
                <input
                    className="search search-style px-5"
                    autoComplete="off"
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={placeholder ? placeholder : 'Search...'}
                    // defaultValue={searchKeyword}
                    onChange={(e) => {
                        handleSearch(e.target.value);
                    }}
                />
                <span className='position-absolute search-icon'><FiSearch /></span>
            </div>
        </>

    )
}

export default Index
import React, { useEffect, useRef, useState } from 'react';

// import * as Yup from 'yup';
import { Button, Input, Spinner } from 'reactstrap';
import { useToasts } from 'react-toast-notifications';
import service from '../../api/services';
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { useNavigate } from 'react-router-dom';
import './viewphoto.scss'
import { useParams } from 'react-router-dom';


const Index = ({ photosList }: any) => {
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageUrl, setImageUrl] = useState<any>([]);
    const [imageArr, setImageArr] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [selectedImageId, setSelectedImageId] = useState('');
    const [loading, setLoading] = useState(true);
    const [isBlack, setIsBlack] = useState(false);
    const { addToast } = useToasts();
    const params = useParams()
    const chooseFileToUpload = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    };
    const navigate = useNavigate()
    const handleImageLoad = () => {
        setLoading(false);

    };

    const _handleImageChange = (e: any) => {
        if (e.target.files.length > 0) {
            let image_Url = URL.createObjectURL(e.target.files[0]);
            setImagePreview(image_Url);
            setImageUrl(e.target.files);
            console.log("e.target.files", e.target.files);
        }

    };

    const toggleCheckbox = (_id: any) => {
        console.log("Image ids")
        if (selectedItems.includes(_id)) {
            setSelectedItems(selectedItems.filter((_id: any) => _id !== _id));
        } else {
            setSelectedItems([...selectedItems, _id]);
        }
    };

    const handleSubmit = async (value: number) => {
        const formData = new FormData();
        console.log("Image URL", imageUrl.length, imageUrl[0])
        for (let i = 0; i < imageUrl.length; i++) {
            formData.append("photos", imageUrl[i]);
        }
        try {
            let payload
            if (value == 1) {
                payload = {
                    selected_ids: selectedItems,
                    is_draft: false

                }
            } else if (value == 0) {
                payload = {
                    selected_ids: selectedItems,
                    is_draft: true
                }
            }


            let response: any = await service.GetPhotos.status(params.id, payload)
            console.log("Viewphotos", response)
            addToast(response?.data?.message, {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
            getPhotos()
        } catch (error) {
            console.log(error)
            addToast("already photos selected", {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
            navigate("/viewphotos/:id")
        }
    };


    const getPhotos = async () => {
        let tempSelected:any = [...selectedItems]
        try {
            let response: any = await service.GetPhotos.view(params.id)
            console.log("managephotos", response)
            response.data.data.map((item:any,ind:number)=>{
                  if(item.status === true){
                     tempSelected.push(item._id)
                  }
            })
            setSelectedItems(tempSelected)
            setImageArr(response.data.data)
        } catch (error) {

        }
    };
    const imageClass = (itemId: any) => {
        return selectedItems.includes(itemId) ? 'image-opacity dim' : 'image-opacity';
    };


    useEffect(() => {
        setImageUrl(imageUrl);
        setImagePreview('');
        getPhotos();


    }, [imageUrl]);

    return (
        <>
            <div className="my-0 py-0">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            {/* <Breadcrumb breadcrumbItem="Images List" /> */}
                            Photos
                        </div>

                    </div>

                    <div className="row">
                        {imageArr && imageArr.map((item: any, index: number) => (
                            <div key={item._id} className="col-md-4 mt-4">
                                <div className="card card-imgs"
                                    style={{
                                        opacity: selectedItems.includes(item._id) ? 0.6 : 1,
                                        backgroundColor: selectedItems.includes(item._id) ? 'rgba(0, 0, 0, 0.5)' : 'transparent'
                                    }}
                                >
                                    <Input type="checkbox" className='chekbos'
                                        checked={selectedItems.includes(item._id)}
                                        onChange={() => toggleCheckbox(item._id)} />
                                    <div className="mt-0 mb-2">
                                        {loading && (

                                            <div className="backdrop py-5">
                                                {/* <ProgressBar variant="buffer" value={progress} valueBuffer={buffer}/> */}
                                                {/* <CircularProgressWithLabel  /> */}
                                                <Spinner animation="border" role="status">
                                                </Spinner>

                                            </div>
                                        )}


                                        <img className={`img-fluid img-card ${imageClass(item._id)}`}

                                            src={`https://photographer-api.codezilla.io/${item.image_url}`}
                                            alt={`Image ${index}`}
                                            onLoad={handleImageLoad} />
                                        {selectedItems.includes(item._id) && loading && (
                                            <div className="backdrop py-5">
                                                <Spinner animation="border" role="status" />
                                            </div>
                                        )}

                                        {/* <div className="delete-icon" onClick={() => {
                                            setSelectedImageId(item._id)
                                           
                                        }}>
                                        </div> */}

                                    </div>
                                    <div className='img-heading'>  <p className='pt-3'>{item?.image_name}</p> </div>
                                </div>

                                <div className="justify-content-center d-flex mt-4">
                                </div>
                            </div>

                        ))}

                    </div>
                    <Button
                        id='submitButton' className="btn-image w-25" onClick={() => {
                            handleSubmit(1);
                        }} >
                        Submit
                    </Button>
                    <Button
                        id='submitLater' className="btn-image-outline w-25 mx-4" onClick={() => {
                            handleSubmit(0);
                        }} >
                         Save and Submit Later
                    </Button>
                </div>

            </div>
        </>
    );
};

export default Index;
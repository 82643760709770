import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { manageevents } = Dictionary

const ManageEvents = {
  view: async (paramsObj:any) => {
    // console.log("paramsObj", paramsObj)
    return axios.get(BASE_URL + manageevents.eventsPhotos(),{ params: paramsObj })
  },
  add: async (data: {}) => {
    return axios.post(BASE_URL + manageevents.addPhotog(), data)
  },
  status: async (id: string) => {
    return axios.put(BASE_URL + manageevents.photosById(id))
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + manageevents.photosById(id), data)
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + manageevents.photosById(id))
  },
}
export default ManageEvents

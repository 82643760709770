import auth from './auth'
import dashboard from './dashboard'
import getphotos from './getphotos'
import manageevents from './manageevents'
const dictionary = {
    auth,
dashboard,
manageevents,
getphotos


}
export default dictionary
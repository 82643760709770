import React, { useState } from "react";
// import { FiEye } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import constant from '../../constants/constant'
import atoms from '../../components/atoms'
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    Label, Input, Modal, Form, FormFeedback,
    ModalHeader,
} from "reactstrap"
import './manage.scss'
const { Table, } = atoms

const Index = ({ setLimitPerPage, setCurrentPage, eventsList, }: any) => {
    const navigate = useNavigate()
    console.log("evenlist", eventsList)

    const [showModal, setShowModal] = useState(false);

    const validation = useFormik<any>({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            oldPassword: '',
            newPassword: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Please Enter Your oldPassword"),
            newPassword: Yup.string().required("Please Enter Your newPassword"),
        }),
        onSubmit: (values) => {
            setShowModal(false);

            // dispatch(loginUser(values, props.router.navigate));
        }
    });
    const columns = [
        {
            name: 'S.No',
            selector: (row: any, index: number) => (index + 1).toString().padStart(2, '0'), width: "50px",
        },
        {
            name: 'Events',
            selector: (row: any) => row?.name, minWidth: "10px"
        },

        {
            name: 'Photo Status',
            selector: (row: any) => row?.status,
        },
        {
            name: 'Selected Photos',
            selector: (row: any) => row?.selected_photos,
        },
        {
            name: 'View Photos',
            selector: (row: any) => row.action,
        },
    ];
    return (
        <div>
            <div>
                <Table
                    pagination={true}
                    paginationTotalRows={eventsList?.limit}
                    setLimitPerPage={(e: number) => setLimitPerPage(e)}
                    setCurrentPage={(e: number) => setCurrentPage(e)}
                    columns={columns}
                    data={eventsList && eventsList
                        ?.map((item: any, index: number) => {
                            return ({
                                ...item,
                                status: <div className={`${item.status === true ? "green-status" : "red-status"}`}>{item.status === true ? "Submitted" : "Not Submitted"}</div>,
                                isSubmitted: item.isSubmitted ? (
                                    <div className="green-status">
                                        <p className="color-green m-1 p-1">submitted</p>
                                    </div>) : <div className="red-status">
                                    <p className="color-red m-1 p-1">Not Submitted</p>
                                </div>,
                                action:

                                    <> <div className='table-data-width'>
                                        <button
                                            className="edit-btn pe-3 px-2 py-2 "
                                            onClick={() => navigate(`/viewphotos/${item._id}`)}
                                        >
                                            <span className='ms-2 mb-0'>Select Photos</span>
                                        </button>
                                        <>
                                            {/* <div>

                                            </div> */}
                                        </>
                                    </div></>




                                // ========================================================
                                // ...item,
                                // status: <div className={`${item.status === "active" ? "green-status" : "red-status"}`}>{item.status}</div>,
                                // action:
                                //     <Link
                                //         to={`/contractors/edit/${item.id}`}
                                //     >
                                //         <Button className=" btnEdit table-edit-button fs-12">
                                //             <div className='d-flex align-items-center'>
                                //                 <FiEye />
                                //                 <span className='ms-2 mb-0'>View</span>
                                //             </div>
                                //         </Button>
                                //     </Link>





                            })
                        })
                    }
                />
            </div>
        </div>
    )
}

export default Index
import React, { useState } from 'react'
// import image from '../../../assets/images/login-card.png'
import { Input, Label } from "reactstrap";
import './forgot.scss'
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import services from '../../../api/services'

const Index = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts();
  const [errors, setErrors] = useState<any>({});
  const [username, setUsername] = useState('');
 
  const [isUsernameValid, setUsernameValid] = useState(true);

  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value);
    setUsernameValid(true);
  };

 
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Perform validation
    let isValid = true;

    if (username.trim() === '') {
      setUsernameValid(false);
      isValid = false;
    }

    
    if (isValid) {
      let payload = {
        "email": username,
      }

      try {
        let response = await services.auth.forgotPassword(payload)
        if (response?.status === 200) {
          localStorage.setItem("passwordResponse", JSON.stringify(response.data.data))
          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        //   localStorage.setItem("access_token", response?.data?.data?.accessToken)
          navigate("/login")
        }
      } catch (error: any) {
        console.log(error)
        addToast(error?.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }

    }
  };
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='login-card-parent d-flex align-items-center justify-content-center'>

            <div className='login-card' >
              <div className='row'>
                <div className='col-md-6 '>
                  <form onSubmit={handleSubmit}>
                    <div className="col-md-6 mx-5">  <h2 className='para-login mt-5'>Forgot Password</h2> 
                     </div>
                   

                    <div className="col-md-6 mx-5 mt-5">
                      <div className="col-md-6"><Label className="form-label email">Email Address</Label> 
                      <Input type={"email"}
                        errors={errors.email}
                        className="form-control e-form mx-4" placeholder="Enter your email address"
                        onChange={handleUsernameChange} label={"Email address"}
                        defaultValue={username}
                      />
                      {!isUsernameValid && <p className="error-text">Email is required</p>}
                      </div>
                    </div>
                    <div className=" col-md-6 mx-5 mt-3">
                    </div>

                    <div className='m-5'>
                      <div className=" d-flex align-items-center text-white mx-4 ">
                        <button className="btn py-2 px-5" type={"submit"}>Send Email</button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-md-6">
                  <div className='login-image'></div>

                  {/* <img src={image} className='image-login' alt="image"/>  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default Index

import ManagePhotos from './ManageEvents'
import App from './App'
import Dashboard from './dashboard'
// import managephotographer from './managephotographer'
import myDashboard from './MyDashboard'
import ManageEvents from './ManageEvents'
import ViewPhotos from './ViewPhotos'
const molecules = {
    Dashboard,
    // managephotographer,
    App,
    myDashboard,
    ManageEvents,
    ViewPhotos

    
}
export default molecules
import React from 'react'
// import './commonCard.scss'

const CommonCard = ({ children,classNames }: any) => {
  return (

    <div className={`card ${classNames}`} >
      <div className="card-body">
        {children}
      </div>
    </div>
  )
}

export default CommonCard

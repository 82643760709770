import React, { useEffect, useState } from "react";
import Wrapper from "../../layout/Wrapper/wrapper";
import Card from "../../layout/Card/card";
import atoms from "../../components/atoms";
// import constant from "../../constants/constant";
import { validationRules } from "./validate";
import { useParams } from "react-router-dom";
import "../../molecules/ManageEvents/manage.scss";
import './addphoto.scss'
import * as Yup from "yup";
import { useFormik } from "formik";
import { Label, Modal, Form, FormFeedback, ModalHeader, } from "reactstrap"

const { Input, Button, } = atoms;
const Index = ({
  photoData,
  photographerData,
  setPhotographerData,
  setButtonClick,
}: any) => {
  const params: any = useParams();
  const editId = params?.id
  // const inputRef = useRef<HTMLInputElement | null>(null);
  const [errors, setErrors] = useState<any>({});


  const handleChange = (name: string, value: any) => {
    console.log("value==>>", name, value);

    setPhotographerData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  };

  const [showModal, setShowModal] = useState(false);

  const validationModal = useFormik<any>({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Please Enter Your oldPassword"),
      newPassword: Yup.string().required("Please Enter Your newPassword"),
    }),
    onSubmit: (values) => {
      setShowModal(false);

      // dispatch(loginUser(values, props.router.navigate));
    }
  });
  const validate = () => {
    let newErrors: any = {};
    for (const field in validationRules) {
      if (validationRules.hasOwnProperty(field)) {
        if (validationRules[field]?.required && !photographerData[field]) {
          newErrors[field] = "This field is required.";
        } else if (
          validationRules[field]?.label?.required &&
          !photographerData[field]?.label
        ) {
          newErrors[field] = "Please Select field";
        }
        if (!photographerData.password && !params.id) {
          newErrors.password = "This field is required.";
        }
      }
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    const isValid = validate();
    console.log("isVa...", isValid, errors);
    if (isValid) {
      setButtonClick(true);
    } else {
      setButtonClick(false);
    }
  };

  useEffect(() => {
    console.log("photographerData", photoData)
  }, [photographerData, photoData])
  return (
    <>
      <Wrapper>
        <Card>
          <div className="row align-items-center mb-4">
            <div className="col-6 text-start ">
              <h4 className="mb-0 textnew fw-600">
                {editId ? "Update" : "Create"} Photographer
              </h4>
            </div>
            {editId && <div className="col-6 text-end ">

              <Button
                className="color p-2 px-4"
                onClick={() => setShowModal(true)}>Change Password</Button>
              <div className="col-12">
                <Modal
                  className="modal-dd py-5"
                  isOpen={showModal}
                  role="dialog"
                  autoFocus={true}
                  centered
                  data-toggle="modal"
                  toggle={() => {
                    setShowModal(!showModal);
                  }}
                // style={{ width: "50vh" }}
                >

                  <ModalHeader className="justify-content-center m-header">
                    Change Password
                  </ModalHeader>
                  <div className="modal-body py-5 px-5">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationModal.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="col-12">
                        <div className="mb-3 col-12 mx-5">
                          <Label className="form-label mx-5">New Password</Label>
                          <Input
                            name="newPassword"
                            className="form-control mx-5"
                            placeholder="Type your New Password"
                            type="password"
                            // onChange={validation.handleChange}
                            // onBlur={validation.handleBlur}
                            value={validationModal.values.newPassword || ""}
                          // invalid={
                          //     validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                          // }
                          />
                          {validationModal.touched.newPassword && validationModal.errors.newPassword ? (
                            <FormFeedback type="invalid"></FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 mx-5">
                          <Label className="form-label mx-5"> Confirm New Password</Label>
                          <Input
                            name="newPassword"
                            className="form-control mx-5"
                            placeholder="Confirm  New Password"
                            type="password"
                            // onChange={validation.handleChange}
                            // onBlur={validation.handleBlur}
                            value={validationModal.values.newPassword || ""}
                          // invalid={
                          //     validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                          // }
                          />
                          {validationModal.touched.newPassword && validationModal.errors.newPassword ? (
                            <FormFeedback type="invalid"></FormFeedback>
                          ) : null}
                        </div>
                        <div className="mt-3 text-center">
                          <button
                            className="btn btn-modal px-5"
                            type="submit"
                          // onClick={()=>handleClick(validation.values)}
                          >
                            Confirm Password
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Modal >
              </div>
              {/* <Button>Change Password</Button> */}
            </div>}
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <Input
                errors={errors.firm_name}
                label={"Firm Name"}
                type={"text"}
                placeholder={"Enter Firm Name"}
                value={photographerData?.firm_name}
                onChange={(e: any) => {
                  handleChange("firm_name", e.target.value);
                }}
              />
            </div>
            <div className="col-6">
              <Input
                errors={errors.name}
                label={"Owner Name"}
                type={"text"}
                placeholder={"Enter Owner Name"}
                value={photographerData?.name}
                onChange={(e: any) => {
                  handleChange("name", e.target.value);
                }}
              />
            </div>

            <div className="col-6 mt-3">
              <Input
                errors={errors.mobile}
                label={"Phone Number"}
                type={"number"}
                placeholder={"Enter Phone Number "}
                value={photographerData?.mobile}
                onChange={(e: any) => {
                  handleChange("mobile", e.target.value);
                }}
              />
            </div>
            <div className="col-6 mt-3">
              <Input
                errors={errors.email}
                label={"Email"}
                type={"text"}
                placeholder={"Enter Email"}
                value={photographerData?.email}
                onChange={(e: any) => {
                  handleChange("email", e.target.value);
                }}
              />
            </div>
           
            <div className="col-6 mt-3">
              <Input
                errors={errors.firm_address}
                label={"Address"}
                type={"text"}
                placeholder={"Enter Address"}
                value={photographerData?.firm_address}
                onChange={(e: any) => {
                  handleChange("firm_address", e.target.value);
                }}
              />
            </div> 
        
            <div className="col-6 mt-3">
              <Input
                errors={errors.password}
                label={"Password"}
                type={"text"}
                placeholder={"Enter Password "}
                value={photographerData?.password}
                onChange={(e: any) => {
                  handleChange("password", e.target.value);
                }}
              />
            </div>
            <div className="col-6 mt-3">
              <Input
                errors={errors.address}
                label={"Confirm Password"}
                type={"text"}
                placeholder={"Confirm Password "}
                value={photographerData?.confirmPassword}
                onChange={(e: any) => {
                  handleChange("", e.target.value);
                }}
              />
            </div>
          </div>


          <div className="row mt-4">

          </div>
          {/* </div> */}
          <div className="justify-content-center d-flex mt-4">
            <Button
              name={`${params.id ? "Update" : "Create"} Photographer`}
              className={"w-25 m-4 p-2"}
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>

        </Card>
      </Wrapper>
    </>
  );
};

export default Index;

import ManageEvents from '../services/manageevents'
import auth from './auth'
import dashboard from './dashboard'
import GetPhotos from './getphotos'
import getphotos from './getphotos'

const service:any = {
  auth,
 dashboard,
 ManageEvents,
 GetPhotos
}
export default service

export const validationRules: any = {
  name: {
    required: true,
  },
  firm_name: {
    required: true,
  },
  email: {
    required: true,
  },
  mobile: {
    required: true,
  },
  password: {
    required: true,
  },


}

  
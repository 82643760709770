import React from 'react'
import Wrapper from '../../layout/Wrapper/wrapper'
import CommonCard from '../../layout/Card/CommonCard/commoncard'
import molecules from '../../molecules'
import atoms from '../../components/atoms'
import { FiClock } from 'react-icons/fi'
import { PiChartBarBold } from 'react-icons/pi'
import { BiUser } from 'react-icons/bi'
import { VscBriefcase } from "react-icons/vsc";
import './index.scss'
import TopBar from '../../layout/Topbar/topbar'
import { NavLink } from 'reactstrap'
const { Button } = atoms

const Index = ({ managePhotos,photoList,dashboard,  }: any) => {
  console.log("dashboard", dashboard)
  return (
    <>
      <Wrapper>
       <div className="col-md-12 card min-vh-100"> 

        <div className='threecards mt-5 mx-4'>
        <CommonCard classNames={"card-body-first"}>
            <Button className='card_btn_first' >
              <PiChartBarBold className='icon_btn' />
            </Button>
            {/* <h4 className='card_font'>Manage photographer</h4> */}
            <h4 className='h-para'>Total Photographers</h4>
            <div className='mb-3 h-num'> <h3> {managePhotos?.total_photographers}</h3> </div>
          </CommonCard>
          <CommonCard classNames={"card-body-first mx-5"}>
            <Button className='card_btn_first' >
              <PiChartBarBold className='icon_btn' />
            </Button>
            <NavLink>
            {/* <h4 className='card_font'>Manage photographer</h4> */}
            <>
            {console.log("managePhotos",managePhotos)}
            </>
            <h4 className='h-para'>Total Events</h4>
            <div className='mb-3 h-num'> <h3> {managePhotos?.total_events}</h3> </div>
            </NavLink>
          </CommonCard>

         
          {/* <CommonCard classNames={"card-body-thrd"}>
            <Button className='card_btn_thrd' >
              <BiUser className='icon_btn' />
            </Button>

            <h4 className='card_font'>Manage events</h4>
            <h1></h1>
            <div className='mb-3'>last month</div>
          </CommonCard> */}
        </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Index
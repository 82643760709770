
// import App from "./App"

import AddPhotographer from "./AddPhotographer"
import ManageEvents from "./ManageEvents"
// import ManagePhotos from "./ManageEvents"
import MyDashboard from "./MyDashboard"
import ViewPhotos from "./ViewPhotos"

const organisms = {

    MyDashboard,
    ManageEvents,
    AddPhotographer,
    ViewPhotos

}
export default organisms
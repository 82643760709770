import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { getphotos } = Dictionary

const GetPhotos = {
  view: async (id:string,paramsObj:any) => {
    return axios.get(BASE_URL + getphotos. getPhotos(id),{ params: paramsObj })
  },
  status: async (id: string ,data:{}) => {
    return axios.put(BASE_URL + getphotos.photosById(id),data)
  },

  getDetailById: async (id: string) => {
    return axios.put(BASE_URL + getphotos.photosById(id))
  },
}
export default GetPhotos


import App from '../../molecules/App'
import MyDashboard from '../../pages/MyDashboard'
import ManageEvents from '../ManageEvents'
import ViewPhotos from '../ViewPhotos'
import Dashboard from './Dashboard'
import ManagePhotographer from './Managephotographer'


const Pages={
    Dashboard,
   ManagePhotographer,
   App,
   MyDashboard,
   ManageEvents,
   ViewPhotos
}
export default Pages

import React, { useEffect, useState } from 'react'

// import atoms from '../../components/atoms'
import service from '../../api/services'
import organisms from '../../organism'
// const { Table, Button,  } = atoms
const { ViewPhotos } = organisms
const Index = () => {
    const [photosList, setPhotosList] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [search, setSearch] = useState('');


    // const getPhotosList = async () => {
    //     try {
    //         let response = await service.ManagePhotos.view({ "page": currentPage, "search": search, "limit": limitPerPage })
    //         console.log("responseSearch", response);
    //         if (response?.status === 200) {
    //             setPhotosList(response.data.data)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // useEffect(() => {
    //     getPhotosList()
    // }, [currentPage, search, limitPerPage])

    // const handleStatus = async (id: string) => {
    //   try {
    //     let response = await service.contractor.status(id);
    //     console.log('hiii status response',response)
    //     if (response.status === 200) {
    //       // addToast('Status updated successfully', {
    //       //   appearance: 'success',
    //       //   autoDismiss: true,
    //       //   autoDismissTimeout: 3000,
    //       // });
    //       getContactorList()
    //     }
    //   } catch (error) {

    //   }
    // }
    return (
        <>
            <ViewPhotos
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                photosList={photosList}
                setSearch={setSearch}
            />
        </>
    )
}

export default Index
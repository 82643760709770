import React from 'react'
import Wrapper from '../../layout/Wrapper/wrapper'
import atoms from '../../components/atoms'
import{MdOutlinePhotoLibrary} from 'react-icons/md'
import './index.scss'

import { useNavigate } from 'react-router-dom';

const { Button } = atoms





const Index = ({ managePhotos,photoList,dashboard,  }: any) => {
  // const [dashboard_Data, setDashboard_Data] = useState<any>([])
  const navigate = useNavigate();
  console.log("dashboard", dashboard)
  return (
    <>
      <Wrapper>
       <div className="col-md-12 card min-vh-100"> 

        <div className='threecards mt-5 mx-5'>
        <div className="card-body-first pt-3 px-5"onClick={() => navigate('/manageevents')}>
              <MdOutlinePhotoLibrary className='person-l' />
            <p className='h-para mt-3'>Events List</p>
            <div className='mb-3 h-num'> </div>
          </div>
          {/* <div className={"card-body-first pt-3 px-5 mx-5"} onClick={() => navigate('/manageevents')}>
              <TbCalendarStar className='person-l' />
            <NavLink>
            <h4 className='card_font'>Manage photographer</h4>
            <>
            {console.log("managePhotos",managePhotos)}
            </>
            <p className='h-para'>Manage Events</p>
            <div className='mb-3 h-num'> <h3></h3> </div>
            </NavLink>
          </div> */}

         
        
        </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Index
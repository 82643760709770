import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { dashboard } = Dictionary

const Dashboard = {
  view: async () => {
    return axios.get(BASE_URL + dashboard.dashboard(), )
  },
  add: async (data: {}) => {
    return axios.post(BASE_URL + dashboard.dashboard(), data)
  },
//   getDetailById: async (id: string) => {
//     return axios.get(BASE_URL +dashboard.dashboardById(id))
//   },
}
export default Dashboard
